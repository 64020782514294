import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import msgSlice from './msgSlice';

import Receiver from './Receiver';
import Sender from './Sender';

const Message = () => {
  const dispatch = useDispatch();
  // channel
  const channel = useSelector(state => state.msg.channel);
  const updateChannel = event => {
    dispatch(msgSlice.actions.setChannel({ channel: event.target.value }))
  };
  // role
  const [role, setRole] = useState('receiver');
  const onRoleChange = (_event, newRole) => {
    setRole(newRole);
  };
  return (
    <Container
      sx={{
        '& .MuiTextField-root': { m: 1, width: '30ch' },
        '& .MuiButton-root': { m: 1, width: '30ch'},
        '& .MuiToggleButtonGroup-root': { m: 1, width: '30ch'},
        '& .MuiPaper-root': { my: 1, width: '32ch'},
        my: 2,
      }}
      noValidate
      autoComplete="off"
    >
      <h1>Message</h1>
      <div>
        <TextField
          id="channel"
          label="Channel"
          value={channel}
          onChange={updateChannel}
        />
      </div>
      <div>
        <ToggleButtonGroup
          color="primary"
          value={role}
          exclusive
          onChange={onRoleChange}
          aria-label="Platform"
        >
          <ToggleButton value="receiver">Receiver</ToggleButton>
          <ToggleButton value="sender">Sender</ToggleButton>
        </ToggleButtonGroup>
      </div>
      { role === "receiver" ? <Receiver /> : <Sender /> }
    </Container>
  );
};

export default Message;
