const subtle = window.crypto.subtle;

const arrBufToBase64 = (bufArr) => {
  const str = String.fromCharCode.apply(null, new Uint8Array(bufArr));
  return window.btoa(str);
};

const base64ToArrBuf = (strBase64) => {
  const binaryStr = window.atob(strBase64);
  const arrBuf = new ArrayBuffer(binaryStr.length);
  const bufView = new Uint8Array(arrBuf);
  for (let i = 0, strLen = binaryStr.length; i < strLen; i++) {
    bufView[i] = binaryStr.charCodeAt(i);
  }
  return arrBuf;
};

// export keys to base64 string
const key2str = async (key, format) => {
  const exported = await subtle.exportKey(format, key);
  return arrBufToBase64(exported);
};

// import keys from base64 string
const str2key = async (keyStr, format, usages) => {
  const arrBuf = base64ToArrBuf(keyStr);
  return subtle.importKey(
    format,
    arrBuf,
    { name: "RSA-OAEP", hash: "SHA-256" },
    true,
    usages,
  );
};

const generateKeyPair = async () => {
  const { publicKey, privateKey } = await subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-256",
    },
    true,
    ["encrypt", "decrypt"],
  );
  return {
    pub: await key2str(publicKey, 'spki'),
    pri: await key2str(privateKey, 'pkcs8'),
  }
};

const encryptWithPubKey = async (msg, pub) => {
  const key = await str2key(pub, 'spki', ['encrypt']);
  const encrypted = await subtle.encrypt(
    { name: "RSA-OAEP" },
    key,
    new TextEncoder().encode(msg)
  );
  return arrBufToBase64(encrypted);
};

const decryptWithPriKey = async (encrypted, pri) => {
  const key = await str2key(pri, 'pkcs8', ['decrypt']);
  const encryptedArrBuf = base64ToArrBuf(encrypted);
  const decrypted = await subtle.decrypt(
    { name: "RSA-OAEP" },
    key,
    encryptedArrBuf
  );
  return new TextDecoder().decode(decrypted);
}

const CryptoService = {
  generateKeyPair,
  encryptWithPubKey,
  decryptWithPriKey,
};

export default CryptoService;
