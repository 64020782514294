import axios from 'axios';

const login = async ({ password }) => {
  const res = await axios.post('/api/token', { pass: password });
  const { data: { token } } = res;
  localStorage.setItem('token', token);
  return token;
};

const logout = () => {
  localStorage.removeItem('token');
};

const authHeader = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return {};
  }
  return { 'x-access-token': token };
};

const uploadPubKey = async (channel, pubKey) => {
  const url = `/api/channels/${channel}`;
  await axios.post(
    url, { pubkey: pubKey }, { headers: authHeader() });
};

const getPubKey = async (channel) => {
  const url = `/api/channels/${channel}/pubkey`;
  const res = await axios.get(url, { headers: authHeader() });
  return res.data.pubkey;
};

const sendMessage = async (channel, message) => {
  const url = `/api/channels/${channel}/message`;
  await axios.post(
    url, { message }, { headers: authHeader() });
}

const getMessage = async (channel) => {
  const url = `/api/channels/${channel}/message`;
  const res = await axios.get(url, { headers: authHeader() });
  return res.data.message;
}

const ApiService = {
  login,
  logout,
  uploadPubKey,
  getPubKey,
  sendMessage,
  getMessage,
};

export default ApiService;
