import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { actions } from './msgSlice';

import { shortenStr } from './services/util';
import { checkIfLoading } from './uiSlice';

const Receiver = () => {
  const { channel, receiver } = useSelector(state => state.msg);
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState('');
  const ui = useSelector(state => state.ui);
  const generating = checkIfLoading(ui, actions.genKeyPairRequest);
  const generateKeyPair = async () => {
    dispatch(actions.genKeyPairRequest({ channel }));
  };
  const gettingMsg = checkIfLoading(ui, actions.getMsgRequest);
  const getMsg = async () => {
    dispatch(actions.getMsgRequest({ channel, priKey: receiver.priKey }));
  };
  const [visible, setVisible] = useState(false);
  const toggleVisible = (e) => {
    e.preventDefault();
    setVisible(!visible);
  }
  const [copied, setCopied] = useState(false);
  const copyMsg = async () => {
    await navigator.clipboard.writeText(receiver.msg);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };
  return (
    <>
      <Paper>
        <div>
          <Button
            variant="contained"
            onClick={generateKeyPair}
            disabled={!channel || generating}>
            New Key Pair
          </Button>
        </div>
        <div>
          <TextField
            id="pubkey"
            label="Pub Key"
            value={shortenStr(receiver.pubKey, 0, 23)}
            InputProps={{readOnly: true}}
          />
        </div>
      </Paper>
      <Paper>
        <div>
          <Button
            variant="contained"
            onClick={getMsg}
            disabled={!receiver.priKey || gettingMsg}>
            Get Message
          </Button>
        </div>
        <div>
          <TextField
            id="receiverMsg"
            label="Message"
            value={ visible ?
              receiver.msg :
              receiver.msg?.replace(/./g, '*')}
            InputProps={{
              readOnly: true,
              endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle visibility"
                  onClick={toggleVisible}
                  onMouseDown={toggleVisible}
                  edge="end"
                >
                  {visible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
            }}
          />
        </div>
        <div>
          <Button
            variant="contained"
            onClick={copyMsg}
            disabled={!receiver.msg}>
            { copied ? 'Copied' : 'Copy'}
          </Button>
        </div>
      </Paper>
      { errMsg && (<div>Error: {errMsg}</div>) }
    </>
  );
};

export default Receiver;
