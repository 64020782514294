import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { actions } from './msgSlice';

import { shortenStr } from './services/util';
import { checkIfLoading } from './uiSlice';

const Sender = () => {
  const { channel, sender } = useSelector(state => state.msg);
  const dispatch = useDispatch();
  const ui = useSelector(state => state.ui);

  // get pub key
  const gettingKey = checkIfLoading(ui, actions.getSenderPubKeyRequest);
  const pullPubKey = async () => {
    dispatch(actions.getSenderPubKeyRequest({ channel }));
  };

  // local update
  const setMessage = (msg) => {
    dispatch(actions.setSenderMsg({ msg }));
  };
  const clearMsg = async (e) => {
    e.preventDefault();
    setMessage('');
  };
  const [pasted, setPasted] = useState(false);
  const pasteMsg = async (e) => {
    e.preventDefault();
    const text = await navigator.clipboard.readText();
    if (text) {
      setMessage(text);
    }
    setPasted(true);
    setTimeout(() => setPasted(false), 2000);
  };
  const [visible, setVisible] = useState(false);
  const toggleVisible = (e) => {
    e.preventDefault();
    setVisible(!visible);
  }

  // send msg
  const submitting = checkIfLoading(ui, actions.sendMsgRequest);
  const sent = checkIfLoading(ui, actions.sendMsgSuccess);
  const handleSubmit = async () => {
    dispatch(actions.sendMsgRequest({
      channel, pubKey: sender.pubKey, msg: sender.msg,
    }));
  }
  return (
    <>
      <Paper>
        <div>
          <Button
            variant="contained"
            onClick={pullPubKey}
            disabled={!channel || gettingKey}>
            Get Pub Key
          </Button>
        </div>
        <div>
          <TextField
            id="pubkey"
            label="Pub Key"
            value={shortenStr(sender.pubKey, 0, 23)}
            InputProps={{readOnly: true}}
          />
        </div>
        { sender.getPubKeyErr && (<div>Error: {sender.getPubKeyErr}</div>) }
      </Paper>
      <Paper>
        <div>
          <TextField
            id="senderMsg"
            label="Message"
            type={visible? "text" : "password"}
            value={sender.msg}
            disabled={!sender.pubKey || submitting}
            onChange={(e) => setMessage(e.target.value)}
            InputProps={{
              endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle visibility"
                  onClick={toggleVisible}
                  onMouseDown={toggleVisible}
                  edge="end"
                >
                  {visible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
            }}
          />
        </div>
        <div>
          <Button variant="contained" onClick={clearMsg}>
            clear
          </Button>
          <Button variant="contained" onClick={pasteMsg}>
            { pasted ? 'Pasted' : 'Paste'}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!sender.msg || submitting}
            onClick={handleSubmit}>
            { sent ? 'Sent' : 'Send'}
          </Button>
        </div>
        { sender.sendMsgErr && (<div>Error: {sender.sendMsgErr}</div>) }
      </Paper>
    </>
  );
};

export default Sender;
