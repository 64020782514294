import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    processingActions: [],
  },
  reducers: {
    start: (state, action) => {
      const name = action.payload.name;
      if (!state.processingActions.includes(name)) {
        state.processingActions.push(name);
      }
    },
    stop: (state, action) => {
      const name = action.payload.name;
      state.processingActions = state.processingActions.filter(
        actionName => actionName !== name);
    }
  },
});

export const startAction = uiSlice.actions.start;
export const stopAction = uiSlice.actions.stop;

export const checkIfLoading = (ui, actionToCheck) => {
  return ui.processingActions.includes(actionToCheck.toString());
}

export default uiSlice;
