import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    channel: 'test',
    receiver: {
      pubKey: '',
      priKey: '',
      msg: '',
      keyPairErr: null,
    },
    sender: {
      pubKey: '',
      msg: '',
      getPubKeyErr: null,
      sendMsgErr: null,
    },
};

const msgSlice = createSlice({
  name: 'msg',
  initialState,
  reducers: {
    setChannel: (_state, action) => ({
      ...initialState,
      channel: action.payload.channel,
    }),

    // gen key pair
    genKeyPairRequest: (state) => {
      state.receiver = initialState.receiver;
    },
    genKeyPairSuccess: (state, action) => {
      state.receiver.pubKey = action.payload.pubKey;
      state.receiver.priKey = action.payload.priKey;
    },
    genKeyPairFailure: (state, action) => {
      state.receiver.keyPairErr = action.payload.err;
    },

    // sender pub key
    getSenderPubKeyRequest: (state) => {
      state.sender = initialState.sender;
    },
    getSenderPubKeySuccess: (state, action) => {
      state.sender.pubKey = action.payload.pubKey;
    },
    getSenderPubKeyFailure: (state, action) => {
      state.sender.getPubKeyErr = action.payload.err;
    },

    // update sender msg
    setSenderMsg: (state, action) => ({
      ...state,
      sender: {
        ...state.sender,
        msg: action.payload.msg,
      }
    }),

    // sender send msg
    sendMsgRequest: (state) => {
      state.sender.sendMsgErr = null;
    },
    sendMsgSuccess: () => {},
    sendMsgFailure: (state, action) => {
      state.sender.sendMsgErr = action.payload.err;
    },

    // get msg
    getMsgRequest: (state) => {
      state.receiver.getMsgErr = null;
    },
    getMsgSuccess: (state, action) => {
      state.receiver.msg = action.payload.msg;
    },
    getMsgFailure: (state, action) => {
      state.receiver.getMsgErr = action.payload.err;
    },
  },
});

export const actions = msgSlice.actions;

export default msgSlice;
