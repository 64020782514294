import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// ref: https://mui.com/material-ui/react-app-bar/

export default function ButtonAppBar({
  alreadyLoggedIn = false,
  logout,
}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          chunfengd
          </Typography>
          { alreadyLoggedIn ?
            <Button color="inherit" onClick={logout}>Logout</Button>
            : null }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
