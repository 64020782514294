import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import authReducer from './authSlice';
import msgSlice from './msgSlice';
import uiSlice from './uiSlice';

import mySaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    msg: msgSlice.reducer,
    ui: uiSlice.reducer,
  },
  middleware:
  (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

// store.subscribe(() => console.log(store.getState()));

sagaMiddleware.run(mySaga);

export default store;
