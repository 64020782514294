
export const shortenStr = (s, pre = 3, post = 4) => {
  if (!s) {
    return '';
  }
  const len = s.length;
  if (len <= pre + post) {
    return s;
  }
  const head = s.substring(0, pre);
  const foot = s.substring(len - post, len);
  return head + '****' + foot;
};
