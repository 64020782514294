import { call, put, takeEvery } from 'redux-saga/effects';

import ApiService from './services/api';
import CryptoService from './services/crypto';
import { startAction, stopAction } from './uiSlice';
import { actions as authActions } from './authSlice';
import { actions as msgActions } from './msgSlice';

const sleep = (sec) => new Promise(resolve => setTimeout(resolve, sec * 1000));

function* handleInvalidToken(err, defaultFailureAction) {
  if (err.response?.status === 401) {
    ApiService.logout();
    yield put(authActions.logout())
  } else {
    yield put(defaultFailureAction);
  }
}

function* login(action) {
  const { username, password } = action.payload;
  try {
    yield put(startAction({ name: action.type }));
    const token = yield call(ApiService.login, { username, password });
    yield sleep(2);
    yield put(authActions.loginSuccess({ token }));
  } catch (err) {
    console.error(err);
    yield put(authActions.loginFailure({ err: err.message }));
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

function* genKeyPair(action) {
  const channel = action.payload.channel;
  try {
    yield put(startAction({ name: action.type }));
    const { pub, pri } = yield call(CryptoService.generateKeyPair);
    yield call(ApiService.uploadPubKey, channel, pub);
    yield put(msgActions.genKeyPairSuccess({ pubKey: pub, priKey: pri}));
  } catch (err) {
    console.error(err);
    yield handleInvalidToken(
      err, msgActions.genKeyPairFailure({ err: err.message }));
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

function* getSenderPubKey(action) {
  const channel = action.payload.channel;
  try {
    yield put(startAction({ name: action.type }));
    const pubKey = yield call(ApiService.getPubKey, channel);
    yield put(msgActions.getSenderPubKeySuccess({ pubKey }));
  } catch (err) {
    console.error(err);
    yield handleInvalidToken(
      err, msgActions.getSenderPubKeyFailure({ err: err.message }));
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

function* sendMsg(action) {
  const { channel, pubKey, msg } = action.payload;
  try {
    yield put(startAction({ name: action.type }));
    const encrypted = yield call(
      CryptoService.encryptWithPubKey, msg, pubKey);
    console.log(`encrypted: ${encrypted}`);
    yield call(ApiService.sendMessage, channel, encrypted);
    yield put(msgActions.sendMsgSuccess());
  } catch(err) {
    console.error(err);
    yield handleInvalidToken(
      err, msgActions.sendMsgFailure({ err: err.message }));
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

function* setSentMark(action) {
  yield put(startAction({ name: action.type }));
  yield call(sleep, 5);
  yield put(stopAction({ name: action.type }));
}

function* getMsg(action) {
  const { channel, priKey } = action.payload;
  try {
    yield put(startAction({ name: action.type }));
    const encrypted = yield call(ApiService.getMessage, channel);
    console.log(`Got encrypted msg: ${encrypted}`);
    const msg = yield call(
      CryptoService.decryptWithPriKey, encrypted, priKey);
    yield put(msgActions.getMsgSuccess({ msg }));
  } catch (e) {
    console.error(e);
    yield handleInvalidToken(
      e, msgActions.getMsgFailure({ err: e.message }));
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

function* mySaga() {
  yield takeEvery(authActions.loginRequest.toString(), login);
  yield takeEvery(msgActions.genKeyPairRequest, genKeyPair);
  yield takeEvery(msgActions.getSenderPubKeyRequest, getSenderPubKey);
  yield takeEvery(msgActions.sendMsgRequest, sendMsg);
  yield takeEvery(msgActions.sendMsgSuccess, setSentMark);
  yield takeEvery(msgActions.getMsgRequest, getMsg);
}

export default mySaga;
