import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Alert from './components/Alert';
import { actions } from './authSlice';
import { checkIfLoading } from './uiSlice';


const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async e => {
    e.preventDefault();
    dispatch(actions.loginRequest({ username, password }));
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const loginErr = useSelector(state => state.auth.err);
  const ui = useSelector(state => state.ui);
  const isLoading = checkIfLoading(
    ui, actions.loginRequest.toString());

  return (
    <form onSubmit={handleSubmit}>
      <Container
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
          '& .MuiButton-root': { m: 1, width: '25ch'},
          my: 2,
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id="username"
            label="Username"
            value={username}
            disabled={isLoading}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <TextField
            label="Password"
            id="password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  onMouseDown={toggleShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
            }}
            required
            value={password}
            disabled={isLoading}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <Button
            type="submit"
            variant="contained" disabled={isLoading}>
            Login
          </Button>
        </div>
        {loginErr && (<Alert severity="error">{loginErr}</Alert>)}
      </Container>
    </form>
  )
};

export default Login;
