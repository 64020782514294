import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  err: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: () => initialState,
    loginSuccess: (state, action) => ({
      ...state,
      token: action.payload.token,
      err: null,
    }),
    loginFailure: (state, action) => ({
      ...state,
      token: null,
      err: action.payload.err,
    }),
    logout: (state) => ({ ...state, token: null }),
  },
});

export const actions = authSlice.actions;

export default authSlice.reducer;
