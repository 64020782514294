import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Routes, Route, Navigate, BrowserRouter
} from 'react-router-dom';

import { actions } from './authSlice';
import Login from './Login';
import Message from './Message';
import ApiService from './services/api';
import AppBar from './components/AppBar';

const RequireAuth = ({ children }) => {
  const token = useSelector(state => state.auth.token);
  return token ? children : <Navigate to="/login" replace />;
};

const RequireNoAuth = ({ children }) => {
  const token = useSelector(state => state.auth.token);
  return !token ? children : <Navigate to="/" replace />;
};

function App() {
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();
  const isLogin = !!token;
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(actions.loginSuccess({ token }));
    }
  }, [token, dispatch]);
  const logout = () => {
    ApiService.logout();
    dispatch(actions.logout());
  };
  return (
    <BrowserRouter>
      <AppBar alreadyLoggedIn={isLogin} logout={logout}/>
      <Routes>
        <Route path="/" element={
          <RequireAuth><Message /></RequireAuth>
        } />
        <Route path="/login" element={
          <RequireNoAuth><Login /></RequireNoAuth>
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
